import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageSingleAreaAtuacao from './pages/Single_Area_Atuacao';
import PageSobre from './pages/Sobre';
import PageBlog from './pages/Blog';
import PageEsg from './pages/Esg';
import PageSingleNoticia from './pages/Single_Noticia';
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import translate from './pages/translate';
import placeholderTranslate from './pages/Contato_Placeholder_Translate'

// import SvgUse from "./utils/SvgUse";

import Contato from './modules/Telefone';
import EditorJS from './modules/Editor';
import contatoForm from './modules/Contato';

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

window.addEventListener('DOMContentLoaded', () => {
	console.log('dataLang ao reload', localStorage.getItem('dataLang'));
	//
	if (localStorage.getItem('dataLang') === 'null') {
		document.documentElement.setAttribute('lang', 'pt-br');
		console.log('NULL - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
	if (localStorage.getItem('dataLang') === 'pt-br') {
		document.documentElement.setAttribute('lang', 'pt-br');
		console.log('PT - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
	if (localStorage.getItem('dataLang') === 'en') {
		document.documentElement.setAttribute('lang', 'en');
		console.log('PT - data-lang', localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
	}
});

/// Cookies
 window.addEventListener('DOMContentLoaded', () => {
 	const btnOpenCookies = document.querySelector('.btn-cookies-open')
 	const btnCloseCookies = document.querySelector('.btn-cookies-close')

 	console.log("Página visitada",localStorage.getItem('hasVisited'));
	
 	if (localStorage.getItem('hasVisited') === 'true') {
 		btnCloseCookies.click();	
 	} else {
		btnOpenCookies.click();
		localStorage.setItem('hasVisited', 'true');
	}
 });

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
Contato()
EditorJS()
translate()
placeholderTranslate()
// Pages
PageHome()
PageLogin()?.init()
PageSingleAreaAtuacao()
PageSobre()
PageBlog()
PageEsg()
PageSingleNoticia()

contatoForm()

// utils logs
logs()
