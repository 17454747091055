import { find, findAll } from "../utils/dom";

const PageSingleNoticia = () => {
    const page = find("[js-page='single_noticias']");
    if (!page) return;

    const BaseURL = window.location.href;


    function compartilhe_facebook() {
        return (`http://www.facebook.com/sharer.php?u=${BaseURL}`);
    }

    function compartilhe_instagram() {
        return (`https://instagram.com/direct/new/`);
    }

    function compartilhe_linkedin() {
        return (`https://www.linkedin.com/sharing/share-offsite/?url=${BaseURL}`);
    }



    const BTCompartilhe = findAll('[js-shared]');
    BTCompartilhe?.forEach((bt) => {
        const TipoCompartilhe = bt.getAttribute('js-shared');

        if (TipoCompartilhe == 'facebook') {
            bt.href = compartilhe_facebook();
        }
        else if (TipoCompartilhe == 'instagram') {
            bt.addEventListener("click", (e) => {
                navigator.clipboard.writeText(BaseURL);
            })
            bt.href = compartilhe_instagram();
        }
        else if (TipoCompartilhe == 'linkedin') {
            bt.href = compartilhe_linkedin();
        }



    })
}

export default PageSingleNoticia;