import axios from "axios";
import { find } from "utils/dom"
import { findAll } from "../utils/dom";

const PageSobre = () => {
    const page = find("[js-page='sobre']");
    if (!page) return;

    const URLBASE = `${window.location.protocol}//${window.location.host}`;

    function EstadoIncluso(ArrayEstados, classe) {
        ArrayEstados.forEach((estado) => {
            find(`[js-mapa-brasil-estados][href='${estado}'`).classList.add(`${classe}`);
        })
    }

    function RemoveAllEstadoAtivo() {
        const Estados = findAll('[js-mapa-brasil-estados].estado-ativo');

        Estados?.forEach((estado) => {
            estado.classList.remove('estado-ativo');
        })
    }

    function addLogoSegmento(id) {
        const Segmento = find(`[js-logo-segmento][value='${id}']`);
        if (!Segmento) return;
        console.log("logo-segmento", Segmento);
        
        const LogoMapa = find('[js-logo-segmento-mapa]');
        LogoMapa.src = Segmento.getAttribute('js-logo-segmento');
    }

    function addNomeSegmento(id) {
        const Segmento = find(`[js-logo-segmento][value='${id}']`);
        if (!Segmento) return;

        return Segmento.innerText;
    }

    function addEventCardSegmento() {
        const Segmentos = findAll('.item-accordion');
        if (!Segmentos) return;

        Segmentos.forEach((segmento) => {
            segmento.addEventListener("click", (e) => {
                e.preventDefault();

                const JsKey = segmento.getAttribute('js-card-key');

                if (segmento.classList.contains('active')) {
                    segmento.classList.remove('active');
                    segmento.querySelector('.material-symbols-outlined.google-icon').innerText = "add";
                    find(`[js-accordion-key='${JsKey}']`).classList.remove('active');
                    find(`[js-accordion-content-key='${JsKey}']`).style.display = "none";

                }
                else {
                    segmento.classList.add('active');
                    segmento.querySelector('.material-symbols-outlined.google-icon').innerText = "remove";
                    find(`[js-accordion-key='${JsKey}']`).classList.add('active');
                    find(`[js-accordion-content-key='${JsKey}']`).style.display = "block";

                }
            })
        })

    }

    function addSegmento(key, nome, estado, email, endereco, telefone_1, telefone_2) {
        return (`
            <div js-card-key="${key}" class="item-accordion">
                <button js-accordion-key="${key}" class="open-accordion">
                    <span>${nome} - ${estado}</span>
                    <span class="material-symbols-outlined google-icon">add</span>
                </button>

                <div js-accordion-content-key="${key}" class="content-accordion">
                    <h3>${nome} - ${estado}</h3>
                    <p>
                        ${(email != "") ? `${email}<br>` : ""}
                        ${(telefone_1 != "") ? `${telefone_1}<br>` : ""}
                        ${(telefone_2 != "") ? `${telefone_2}<br>` : ""}
                        ${endereco}
                    </p>
                </div>
            </div>
        `);
    }

    function mapa() {
        const SVGMapaBrasil = find("[js-mapa-brasil]");
        if (!SVGMapaBrasil) return;

        const FormMapa = find("#form-mapa");
        const ListaSegmentos = find('[js-lista-segmentos]');

    
        FormMapa?.addEventListener("submit", (event) => {
            event.preventDefault();


            const formData = new FormData(event.target);

            const DataSegmento = formData.get('segmento');
            const DataEstado = formData.get('estado');


            axios.get(`${URLBASE}/api/pontos-operacao/${DataSegmento}`)
                .then(response => {
                    addLogoSegmento(DataSegmento);
                    const Resultado_Dados = response.data;

                    console.log(Resultado_Dados)

                    ListaSegmentos.innerHTML = "";
                    RemoveAllEstadoAtivo()

                    let countEstado = 0;


                    for (let dado of Resultado_Dados) {

                        if (DataEstado == "") {
                            ListaSegmentos.insertAdjacentHTML('beforeend', addSegmento(dado.id, dado.cidade, dado.estado, dado.email, dado.endereco, dado.telefone_1, dado.telefone_2));

                            EstadoIncluso([dado.estado], "estado-ativo")
                            countEstado++;
                        }
                        else if (dado.estado == DataEstado) {
                            ListaSegmentos.insertAdjacentHTML('beforeend', addSegmento(dado.id, dado.cidade, dado.estado, dado.email, dado.endereco, dado.telefone_1, dado.telefone_2));

                            EstadoIncluso([dado.estado], "estado-ativo")
                            countEstado++;
                        }
                    }

                    if (countEstado == 0) {
                        ListaSegmentos.innerHTML = `<p> Nenhum ponto de operação ${addNomeSegmento(DataSegmento)} foi encontrado em ${DataEstado}</p>`;
                    }

                    addEventCardSegmento();

                    find("[js-container-segmentos] .content-text-map").style.display = "block";
                })
                .catch(error => {
                    console.error('Erro ao fazer a requisição GET:', error);
                });

            find("[js-container-mapa]").classList.add('show')
            find("[js-container-segmentos]").classList.add('show')
        })

        const selectMap = FormMapa.querySelector('select[name="segmento"]')
    
        selectMap?.addEventListener("change", (event) => {
            event.preventDefault();

            const formData = new FormData(find("#form-mapa"));
            const DataSegmento = formData.get('segmento');
            const DataEstado = formData.get('estado');

            axios.get(`${URLBASE}/api/pontos-operacao/${DataSegmento}`)
                .then(response => {
                    addLogoSegmento(DataSegmento);
                    const Resultado_Dados = response.data;

                    console.log(Resultado_Dados)

                    ListaSegmentos.innerHTML = "";
                    RemoveAllEstadoAtivo()

                    let countEstado = 0;


                    for (let dado of Resultado_Dados) {

                        if (DataEstado == "") {
                            ListaSegmentos.insertAdjacentHTML('beforeend', addSegmento(dado.id, dado.cidade, dado.estado, dado.email, dado.endereco, dado.telefone_1, dado.telefone_2));

                            EstadoIncluso([dado.estado], "estado-ativo")
                            countEstado++;
                        }
                        else if (dado.estado == DataEstado) {
                            ListaSegmentos.insertAdjacentHTML('beforeend', addSegmento(dado.id, dado.cidade, dado.estado, dado.email, dado.endereco, dado.telefone_1, dado.telefone_2));

                            EstadoIncluso([dado.estado], "estado-ativo")
                            countEstado++;
                        }
                    }

                    if (countEstado == 0) {
                        ListaSegmentos.innerHTML = `<p> Nenhum ponto de operação ${addNomeSegmento(DataSegmento)} foi encontrado em ${DataEstado}</p>`;
                    }

                    addEventCardSegmento();

                    find("[js-container-segmentos] .content-text-map").style.display = "block";
                })
                .catch(error => {
                    console.error('Erro ao fazer a requisição GET:', error);
                });

            /** Classes necessárias para exibição da lista de segmentos. */
            find("[js-container-mapa]").classList.add('show')
            find("[js-container-segmentos]").classList.add('show')

        })

    }

    function loadAllSegmentosMapa() {
        const Segmentos = findAll('[js-segmento]');
        if (!Segmentos) return;

        for (let segmento of Segmentos) {
            const id_segmento = segmento.getAttribute('js-segmento');

            let ArrayEstados = new Array();

            axios.get(`${URLBASE}/api/pontos-operacao/${id_segmento}`)
                .then(response => {

                    const Resultado_Dados = response.data;
                    for (let dado of Resultado_Dados) {
                        ArrayEstados.push(dado.estado);
                        ArrayEstados = [...new Set(ArrayEstados)];
                    }

                    EstadoIncluso(ArrayEstados, "estado-incluso");

                })
                .catch(error => {
                    console.error('Erro ao fazer a requisição GET:', error);
                });

        }

        const urlAtual = window.location.href.split("?")[1].split("&");

        urlAtual?.forEach((slug_atual) => {
            if (slug_atual.includes('segmento')) {
                const ListaSegmentos = find('[js-lista-segmentos]');

                let id_segmento_url = slug_atual.split("=")[1]

                axios.get(`${URLBASE}/api/pontos-operacao/${id_segmento_url}`)
                    .then(response => {
                        addLogoSegmento(id_segmento_url);

                        const Resultado_Dados = response.data;

                        ListaSegmentos.innerHTML = "";
                        RemoveAllEstadoAtivo()

                        let countEstado = 0;

                        for (let dado of Resultado_Dados) {
                            ListaSegmentos.insertAdjacentHTML('beforeend', addSegmento(dado.id, dado.cidade, dado.estado, dado.email, dado.endereco, dado.telefone_1, dado.telefone_2));

                            EstadoIncluso([dado.estado], "estado-ativo")
                            countEstado++;
                        }

                        if (countEstado == 0) {
                            ListaSegmentos.innerHTML = `<p> Nenhum ponto de operação ${addNomeSegmento(DataSegmento)} foi encontrado em ${DataEstado}</p>`;
                        }

                        addEventCardSegmento();

                        find("[js-container-segmentos] .content-text-map").style.display = "block";
                    })
                    .catch(error => {
                        console.error('Erro ao fazer a requisição GET:', error);
                    });

                /** Classes necessárias para exibição da lista de segmentos. */
                find("[js-container-mapa]").classList.add('show')
                find("[js-container-segmentos]").classList.add('show')
            }
        })

    }

    function getParamsSegmento (){
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const segmentoId = params.get('segmento');
        addLogoSegmento(segmentoId)
        console.log('Segmento ID', hasSegmento); // true
    }

    mapa();
    loadAllSegmentosMapa();
    getParamsSegmento()    
}

export default PageSobre;