import { find } from "utils/dom"

const PageContatoPlaceholderTranslate = () => {
    const page = find("[js-page='contato']");
    if (!page) return;
    
     function onLanguageChange(mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
                let newLang = document.documentElement.lang;
                console.log('2 - Idioma alterado para:', newLang);
                const primeiro_nome = document.querySelector('#enter-in-contact input[name=nome]');
                const telefone = document.querySelector('#enter-in-contact input[name=telefone]')
                const email = document.querySelector('#enter-in-contact input[name=email]');
                const box_text = document.querySelector('#enter-in-contact textarea[name=mensagem]');
                const area = document.querySelector('[data-text]')
                const areaNegocio = document.querySelector('[data-text-negocio]')

                if( newLang === 'en'){ 
                    primeiro_nome.placeholder = 'Enter your name'
                    telefone.placeholder = 'Enter your phone'
                    email.placeholder = 'Enter your e-mail'
                    box_text.placeholder = 'Write your message...'
                    area.innerText = 'Select area of ​​interest'
                    areaNegocio.innerText = 'Select the business area'
                }
                else {
                    primeiro_nome.placeholder = 'Digite seu nome'
                    telefone.placeholder = 'Digite seu telefone'
                    email.placeholder = 'Digite seu e-mail'
                    box_text.placeholder = 'Escreva sua mensagem...'
                    area.innerText = 'Selecione a área de interesse'
                    areaNegocio.innerText = 'Selecione a área de negócio'
                }
                
            }
        }
    }
    
    // Cria um observador para monitorar mudanças no elemento <html>
    const observer = new MutationObserver(onLanguageChange);

    const config = { attributes: true, childList: false, subtree: false };
    observer.observe(document.documentElement, config);
}

export default PageContatoPlaceholderTranslate;