import { findAll, find } from "../utils/dom";
import axios from "axios";

const PageBlog = () => {
    const page = findAll(`[js-page='noticias']`);
    if (!page) return;

    /** URL Base */
    const URLBASE = `${window.location.protocol}//${window.location.host}`;

    let Limit_List = 9;
    let Global_Categoria = '';
    let Global_Pesquisa = '';

    function card_post(dados = { thumbnail: '', slug: '', titulo: '', resumo: '', categoria: '' }) {
        let categorias = ''
        if (dados.categoria != '') {
            dados.categoria.forEach((item, key) => {
                if (key == 0)
                    categorias += item.nome;
                else
                    categorias += `, ${item.nome}`
            })
        }
        console.log(dados);
        return (`
            <div class="col-xl-4">
                <div class="card-blog">
                    <a href="${URLBASE}/blog/${dados.slug}" class="image">
                        ${(dados.thumbnail != null || dados.thumbnail != undefined) ? `<img loading="lazy" src="${dados.thumbnail}">` : `<img loading="lazy" src="/static/img/default/default.webp" >`}
                    </a>
                    
                    <span href="${URLBASE}/blog/" class="tag">
                       ${categorias}
                    </span>
                    <a href="${URLBASE}/blog/${dados.slug}" class="title">${dados.titulo}</a>
                    <p>${dados.resumo}</p>
                    <a href="${URLBASE}/blog/${dados.slug}" class="link-more">Continuar lendo <span class="material-symbols-outlined google-icon">chevron_right</span></a>
                </div>
            </div>
        `)
    }

    /** Funcionalidade para destaque da categoria */
    function remove_categoria_destaque() {
        const categorias = findAll('[js-item-categoria].active');
        if (!categorias) return;

        for (let categoria of categorias) {
            categoria.classList.remove('active');
        }

    }

    function add_categoria_destaque(slug_categoria) {
        const categoria = document.querySelector(`[js-item-categoria="${slug_categoria}"]`);
        if (!categoria) return;

        categoria.classList.add('active');
    }

    function categoria_destaque() {

        const URLDados = window.location.href.split('?')[1].split('&')

        URLDados?.forEach((dados) => {
            if (dados.includes('categoria')) {
                remove_categoria_destaque();
                add_categoria_destaque(dados.split('=')[1]);
            }
        })

    }

    function buscar_categoria() {
        const categorias = findAll('[js-item-categoria]');
        if (!categorias) return;

        categorias?.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();

                const categoria = e.target.getAttribute('js-item-categoria');
                Global_Categoria = categoria;
                Global_Pesquisa = '';


                axios.get(`${URLBASE}/api/posts/?categoria=${categoria}`)
                    .then(response => {
                        if (response.data.count == 0) return;

                        const Todos_Post = find('[js-all-post]');
                        if (!Todos_Post) return;

                        Todos_Post.innerHTML = '';

                        for (let dados of response.data.results.slice(0, 9)) {
                            let postInfo = {
                                thumbnail: dados.thumbnail,
                                resumo: dados.resumo,
                                slug: dados.slug,
                                titulo: dados.titulo,
                                categoria: dados.categorias,

                            }

                            Todos_Post.insertAdjacentHTML('beforeend', card_post(postInfo));
                        }

                        Limit_List = 9;
                        remove_categoria_destaque();
                        add_categoria_destaque(categoria);
                    })
                    .catch(error => {
                        console.error('Erro ao fazer a requisição GET:', error);
                    });
            })
        })
    }

    function buscar_post() {
        const form = document.querySelector("#form-busca");
        if (!form) return;

        form.addEventListener("submit", (e) => {
            e.preventDefault();

            Global_Categoria = '';



            const dados = new FormData(e.target);

            const filtro_nome = dados.get('filtro-nome');

            Global_Pesquisa = filtro_nome;

            axios.get(`${URLBASE}/api/posts/?q=${filtro_nome}`)
                .then(response => {
                    if (response.data.count == 0) return;

                    const Todos_Post = find('[js-all-post]');
                    if (!Todos_Post) return;

                    Limit_List = 9;

                    Todos_Post.innerHTML = '';

                    for (let dados of response.data.results.slice(0, 9)) {
                        let postInfo = {
                            thumbnail: dados.thumbnail,
                            resumo: dados.resumo,
                            slug: dados.slug,
                            titulo: dados.titulo,
                            categoria: dados.categorias,

                        }

                        Todos_Post.insertAdjacentHTML('beforeend', card_post(postInfo));
                    }

                })
                .catch(error => {
                    console.error('Erro ao fazer a requisição GET:', error);
                });

        })

    }

    function carregar_mais_post() {
        const botao_carregar_mais = find('[js-btn-more]');
        if (!botao_carregar_mais) return;

        botao_carregar_mais.addEventListener("click", (e) => {
            e.preventDefault();

            Limit_List += 9;

            let stringPesquisa = `limit=${Limit_List}`;
            if (Global_Categoria != '') {
                stringPesquisa += `&categoria=${Global_Categoria}`
            }
            if (Global_Pesquisa != '') {
                stringPesquisa += `&q=${Global_Pesquisa}`;
            }



            axios.get(`${URLBASE}/api/posts/?${stringPesquisa}`)
                .then(response => {
                    if (response.data.count == 0) return;

                    const Todos_Post = find('[js-all-post]');
                    if (!Todos_Post) return;

                    Todos_Post.innerHTML = '';

                    for (let dados of response.data.results) {
                        let postInfo = {
                            thumbnail: dados.thumbnail,
                            resumo: dados.resumo,
                            slug: dados.slug,
                            titulo: dados.titulo,
                            categoria: dados.categorias,

                        }

                        Todos_Post.insertAdjacentHTML('beforeend', card_post(postInfo));
                    }
                })
                .catch(error => {
                    console.error('Erro ao fazer a requisição GET:', error);
                });
        })



    }

    buscar_post();
    carregar_mais_post();
    buscar_categoria();

    function onLanguageChange(mutationsList) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
                let newLang = document.documentElement.lang;
                console.log('2 - Idioma alterado para:', newLang);
                const filterBlog = document.querySelector('[data-text-filter-blog]')

                if (newLang === 'en') filterBlog.placeholder = 'Do a search...'
                else filterBlog.placeholder = 'Faça uma busca...'
            }
        }
    }
    
    // Cria um observador para monitorar mudanças no elemento <html>
    const observer = new MutationObserver(onLanguageChange);

    const config = { attributes: true, childList: false, subtree: false };
    observer.observe(document.documentElement, config);

}


export default PageBlog;