import { findAll } from "../utils/dom";

const Contato = () => {
    const Numeros_Telefones = findAll('[js-tel]');
    if (!Numeros_Telefones) return;

    Numeros_Telefones?.forEach((Numero) => {
        let StringNumero = Numero.getAttribute('js-tel');
        StringNumero = StringNumero.replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");

        Numero.href = `tel:+55${StringNumero}`;
    })


}

export default Contato;