import axios from 'axios'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function contatoForm() {
    const showToastify = (message, opts) => {
        Toastify({
            duration: 3000,
            position: "center",
            style: { background: "rgba(193, 255, 129)", fontSize: "18px", color: "rgba(45, 71, 120, 1)" },
            text: message,
            ...opts
        }).showToast();
    }

    const btnFormContato = document.querySelectorAll('.btn-contact')
    const formContato = document.querySelector('#enter-in-contact')
    
    // ÁREA DO EVENTO DE CHANGE NO SELECT //
    const areaEvent = document.querySelector('#enter-in-contact [data-area-interesse]')

    areaEvent?.addEventListener('change', (e)=>{
        const area_negocio = document.querySelector('#enter-in-contact [data-area-negocio]')
        if (areaEvent.value === 'Solicitação de Cotação') area_negocio.classList.remove('!hidden')
        else area_negocio.classList.add('!hidden')
    })

    areaEvent?.addEventListener('change', (e)=>{
        if (areaEvent.value === 'Solicitação de Cotação'){
            if (btnFormContato) {
                btnFormContato.forEach((btn) => {
                    formContato && btn.addEventListener('click', function (event) {
                        event.preventDefault();
                        disabledBtn()

                        const primeiro_nome = document.querySelector('#enter-in-contact input[name=nome]');
                        const telefone = document.querySelector('#enter-in-contact input[name=telefone]')
                        const email = document.querySelector('#enter-in-contact input[name=email]');
                        const box_text = document.querySelector('#enter-in-contact textarea[name=mensagem]');
                        const area_interesse = document.querySelector('#enter-in-contact select[name=area-interesse]')
                        const area_negocio = document.querySelector('#enter-in-contact select[name=area-negocio]')
 
                        const form_data = {
                            nome: `${primeiro_nome.value}`,
                            email: email.value,
                            mensagem: box_text.value,
                            area_interesse: area_interesse.value,
                            telefone: telefone.value,
                            area_negocio: area_negocio.value,
                        }

                        if (area_negocio.value.includes('Navemestra')){
                            const url = "/fale-conosco/navemestra/"
                            axios.post(url, form_data)
                            .then((response) => showMessage(response))
                            .catch((response) => showMessage(response));
                            setTimeout(function() {
                                formContato.reset()
                            }, 2000);
                        }
                        if (area_negocio.value.includes('Estaleiro')){
                            const url = "/fale-conosco/estaleiro/"
                                    axios.post(url, form_data)
                            .then((response) => showMessage(response))
                            .catch((response) => showMessage(response));
                            setTimeout(function() {
                                formContato.reset()
                            }, 2000);
                        }
                        if (area_negocio.value.includes('Hidroclean')){
                            const url = "/fale-conosco/hidroclean/"
                                    axios.post(url, form_data)
                            .then((response) => showMessage(response))
                            .catch((response) => showMessage(response));
                            setTimeout(function() {
                                formContato.reset()
                            }, 2000);
                        }
                        if (area_negocio.value.includes('Bravante')){
                            const url = "/fale-conosco/offshore/"
                            axios.post(url, form_data)
                                .then((response) => showMessage(response))
                                .catch((response) => showMessage(response));
                                setTimeout(function() {
                                    formContato.reset()
                                }, 2000);
                        } 
                        if (area_negocio.value.includes('RRC')) {
                            const url = "/fale-conosco/rrc/"
                            axios.post(url, form_data)
                            .then((response) => showMessage(response))
                            .catch((response) => showMessage(response));
                            setTimeout(function() {
                                formContato.reset()
                            }, 2000);
                        }

                        //const url = "/fale-conosco/navemestra/"
                        // axios.post(url, form_data)
                        //     .then((response) => showMessage(response))
                        //     .catch((response) => showMessage(response));
                    });
                })
            }
            console.log("Área de atuacao igual");     
        } 
        else {
            if (btnFormContato) {
                btnFormContato.forEach((btn) => {
                    formContato && btn.addEventListener('click', function (event) {
                        event.preventDefault();
                        disabledBtn()
        
                        const primeiro_nome = document.querySelector('#enter-in-contact input[name=nome]');
                        //const assunto = document.querySelector('#enter-in-contact input[name=assunto]');
                        const telefone = document.querySelector('#enter-in-contact input[name=telefone]')
                        const email = document.querySelector('#enter-in-contact input[name=email]');
                        const box_text = document.querySelector('#enter-in-contact textarea[name=mensagem]');
                        const area_interesse = document.querySelector('#enter-in-contact select[name=area-interesse]')
                        const i = document.querySelector('#enter-in-contact select[name=area-negocio]')
        
        
        
                        const form_data = {
                            nome: `${primeiro_nome.value}`,
                            email: email.value,
                            mensagem: box_text.value,
                            area_interesse: area_interesse.value,
                            telefone: telefone.value,
                            //assunto: assunto.value,
                        }
        
                        const url = "/fale-conosco/"
                        axios.post(url, form_data)
                            .then((response) => showMessage(response))
                            .catch((response) => showMessage(response));
                    });
                })
            }
            console.log('Área de atuação diferente');       
        }
    })

    /*
    if (btnFormContato) {
        btnFormContato.forEach((btn) => {
            formContato && btn.addEventListener('click', function (event) {
                event.preventDefault();
                disabledBtn()

                const primeiro_nome = document.querySelector('#enter-in-contact input[name=nome]');
                //const assunto = document.querySelector('#enter-in-contact input[name=assunto]');
                const telefone = document.querySelector('#enter-in-contact input[name=telefone]')
                const email = document.querySelector('#enter-in-contact input[name=email]');
                const box_text = document.querySelector('#enter-in-contact textarea[name=mensagem]');
                const area_interesse = document.querySelector('#enter-in-contact select[name=area-interesse]')
                const area_negocio = document.querySelector('#enter-in-contact select[name=area-negocio]')



                const form_data = {
                    nome: `${primeiro_nome.value}`,
                    email: email.value,
                    mensagem: box_text.value,
                    area_interesse: area_interesse.value,
                    telefone: telefone.value,
                    //assunto: assunto.value,
                }

                const url = "/fale-conosco/"
                axios.post(url, form_data)
                    .then((response) => showMessage(response))
                    .catch((response) => showMessage(response));
            });
        })
    }
*/
    const Languagem = document.querySelector('[lang]').lang;
    console.log(Languagem);

    function showMessage(response) {
        const isSuccess = response.data && response.data.message;
        // const message = response.data ? response.data.message : response.message

        const Languagem = document.querySelector('[lang]').lang;
        let message = ''
        if (Languagem == 'en') {
            message = isSuccess ? 'Message sent successfully! Thank you!' : 'Oops, something went wrong! Try again!'
        } else {
            message = isSuccess ? 'Mensagem enviada com sucesso! Obrigado!' : 'Oops, aconteceu algo de errado! Tente de novo!'
        }

        const backgroundColor = isSuccess ? "rgba(193, 255, 129)" : "rgba(233, 44, 44)";
        const textColor = isSuccess ? "rgba(45, 71, 120, 1)" : "white";

        showToastify(message, {
            style: {
                background: backgroundColor,
                color: textColor,
                fontSize: "18px",
                borderRadius: "8px"
            },
        });
        formContato.reset()
        setTimeout(enabledBtn, 1000)
    }

    function disabledBtn() {
        btnFormContato.forEach((btn) => {
            btn.disabled = true
            btn.classList.add("disabled")
        })
    }

    function enabledBtn() {
        btnFormContato.forEach((btn) => {
            btn.disabled = false
            btn.classList.remove("disabled")
        })
    }
}