import { find, findAll } from "../utils/dom";


const PageEsg = () => {
    const page = find("[js-page='esg']");
    if (!page) return;


    function removeAllMatrizes() {
        const BTsMatrizes = findAll('[js-matriz-bt].active');
        BTsMatrizes?.forEach((bt) => {
            bt.classList.remove('active');
            const valueContent = bt.getAttribute('js-matriz-bt');
            find(`[id='${valueContent}']`).classList.remove('active');
            find(`[id='${valueContent}']`).style.display = "none";
        })
    }

    function addMatrizes(id) {
        const BT = find(`[js-matriz-bt='${id}']`);
        if (!BT) return;

        BT.classList.add('active');
        find(`[id='${id}']`).classList.add('active');
        find(`[id='${id}']`).style.display = "block";
    }



    const BaseURL = window.location.href;

    const todasMatrizes = findAll('[js-matriz-bt]');
    todasMatrizes?.forEach((singleMatriz) => {
        const id = singleMatriz.getAttribute('js-matriz-bt');

        if (BaseURL.includes(id)) {
            removeAllMatrizes();
            addMatrizes(id);
        }

    })


    /*
    const BTsMatrizes = findAll('[js-matriz-bt]');
    BTsMatrizes?.forEach((bt) => {
        bt.addEventListener("click", (e) => {
            const srcTarget = e.target.getAttribute('js-matriz-bt');

            console.log(srcTarget);
        })
    })
    */
}

export default PageEsg;