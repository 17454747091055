export default function translate() {
    const translateDesktop = () => {
        const bntIdioma = document?.querySelector('.itemIdioma')
        const btnChangeLanguage = bntIdioma?.querySelector('.btnChangeLanguage')
        btnChangeLanguage?.addEventListener('click', (e) => {
            const arrow = btnChangeLanguage?.querySelector('.arrowChangeLanguage')
            arrow.classList?.toggle('rotate-180')
            const dropDownLanguage = bntIdioma?.querySelector('.ItensWrapperDropdown')
            dropDownLanguage?.classList.toggle('opacity-0')
            const itensLang = dropDownLanguage?.querySelectorAll('[data-translate]')
            itensLang?.forEach(i => {
                i?.addEventListener('click', () => {
                    const langAtual = document?.querySelector('html[lang]')
                    if (i.dataset.translate === 'en') {
                        langAtual.setAttribute('lang', 'en')

                    }
                    if (i.dataset.translate === 'pt-br')
                        langAtual.setAttribute('lang', 'pt-br')
                })
            })
        })
    }

    translateDesktop()

    const translateMobile = () => {
        const bntIdioma = document?.querySelector('.itemIdiomaMobile')
        const btnChangeLanguage = bntIdioma?.querySelector('.btnChangeLanguage')
        btnChangeLanguage?.addEventListener('click', (e) => {
            const arrow = btnChangeLanguage?.querySelector('.arrowChangeLanguage')
            arrow.classList?.toggle('rotate-180')
            const dropDownLanguage = bntIdioma?.querySelector('.ItensWrapperDropdown')
            dropDownLanguage.classList?.toggle('opacity-0')
            const itensLang = dropDownLanguage?.querySelectorAll('[data-translate]')
            itensLang?.forEach(i => {
                i?.addEventListener('click', () => {
                    const langAtual = document.querySelector('html[lang]')
                    if (i.dataset.translate === 'en') {
                        langAtual.setAttribute('lang', 'en')
                        localStorage.setItem('dataLang', 'en');
                    }
                    if (i.dataset.translate === 'pt-br') {
                        langAtual.setAttribute('lang', 'pt-br')
                        localStorage.setItem('dataLang', 'pt-br');
                    }

                })
            })
        })
    }

    translateMobile()

    function callback(mutationsList, observer) {
        for (let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
                localStorage.setItem('dataLang', document.documentElement.lang);
                console.log(`Data-lang mudou para ${document.documentElement.lang}`);
            }
        }
    }

    // //Criação do MutationObserver
    const observer = new MutationObserver(callback);

    // //Configuração do MutationObserver para observar mudanças nos atributos da tag <html>
    const config = { attributes: true, childList: false, subtree: false };
    observer.observe(document.documentElement, config);

    // window.addEventListener('load', ()=>{
    //     console.log('dataLang ao reload',  localStorage.getItem('dataLang'));
    //     if(localStorage.getItem('dataLang') === 'null') {
    //         document.documentElement.setAttribute('lang','pt-br');
    //         console.log('IF - data-lang',  localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
    //     }
    //     else {
    //         document.documentElement.setAttribute('lang', localStorage.getItem('dataLang'))
    //         console.log('ELSE - data-lang',  localStorage.getItem('dataLang'), 'lang page', document.documentElement.lang);
    //     }
    // });


}