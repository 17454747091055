import Swiper from "swiper"
import { find } from "utils/dom"
import { findAll } from "../utils/dom";
import logs from "../utils/logs";

const PageSingleAreaAtuacao = () => {
    const page = find("[js-page='single-area-atuacao']");
    if (!page) return;

    const EstadosBrasileiros = [
        {
            pronome: "o",
            sigla: "AC",
            estado: "Acre",
        },
        {
            pronome: "a",
            sigla: "AL",
            estado: "Alagoas",
        },
        {
            pronome: "o",
            sigla: "AP",
            estado: "Amapá",
        },
        {
            pronome: "a",
            sigla: "AM",
            estado: "Amazonas",
        },
        {
            pronome: "a",
            sigla: "BA",
            estado: "Bahia",
        },
        {
            pronome: "o",
            sigla: "CE",
            estado: "Ceará",
        },
        {
            pronome: "o",
            sigla: "ES",
            estado: "Espírito Santo",
        },
        {
            pronome: "o",
            sigla: "GO",
            estado: "Goiás",
        },
        {
            pronome: "o",
            sigla: "MA",
            estado: "Maranhão",
        },
        {
            pronome: "o",
            sigla: "MT",
            estado: "Mato Grosso",
        },
        {
            pronome: "o",
            sigla: "MS",
            estado: "Mato Grosso do Sul",
        },
        {
            pronome: "a",
            sigla: "MG",
            estado: "Minas Gerais",
        },
        {
            pronome: "o",
            sigla: "PA",
            estado: "Pará",
        },
        {
            pronome: "a",
            sigla: "PB",
            estado: "Paraíba",
        },
        {
            pronome: "o",
            sigla: "PR",
            estado: "Paraná",
        },
        {
            pronome: "o",
            sigla: "PE",
            estado: "Pernambuco",
        },
        {
            pronome: "o",
            sigla: "PI",
            estado: "Piauí",
        },
        {
            pronome: "o",
            sigla: "RJ",
            estado: "Rio de Janeiro",
        },
        {
            pronome: "o",
            sigla: "RN",
            estado: "Rio Grande do Norte",
        },
        {
            pronome: "o",
            sigla: "RS",
            estado: "Rio Grande do Sul",
        },
        {
            pronome: "a",
            sigla: "RO",
            estado: "Rondônia",
        },
        {
            pronome: "a",
            sigla: "RR",
            estado: "Roraima",
        },
        {
            pronome: "a",
            sigla: "SC",
            estado: "Santa Catarina",
        },
        {
            pronome: "o",
            sigla: "SP",
            estado: "São Paulo",
        },
        {
            pronome: "o",
            sigla: "SE",
            estado: "Sergipe",
        },
        {
            pronome: "o",
            sigla: "TO",
            estado: "Tocantins",
        },
        {
            pronome: "o",
            sigla: "DF",
            estado: "Distrito Federal",
        },

    ]

    function Section_Base() {
        const Base_Estados = findAll('[js-estado]');
        const Base_Cidades = findAll('[js-cidade]');

        if (!Base_Cidades && !Base_Estados) return;

        const textBase = find('[js-text-bases]');
        const textBaseEn = find('[js-text-bases-en]');

        const counterCidade = Base_Cidades.length;

        let ArrayEstados = new Array();
        Base_Estados?.forEach((estado) => {
            ArrayEstados.push(estado.getAttribute('js-estado'));
            ArrayEstados = [...new Set(ArrayEstados)];
        })
        const counterEstados = ArrayEstados.length;

        if (counterCidade == 1) {
            const dadosEstados = EstadosBrasileiros.find((e) => {
                if (e.sigla == ArrayEstados[0]) {
                    return e;
                }
            })
            textBase.innerText = `Conheça uma de nossas bases n${dadosEstados.pronome} ${dadosEstados.estado}`;
            textBaseEn.innerText = `Conheça uma de nossas bases n${dadosEstados.pronome} ${dadosEstados.estado}`;
        }
        else if (counterEstados == 1) {
            const dadosEstados = EstadosBrasileiros.find((e) => {
                if (e.sigla == ArrayEstados[0]) {
                    return e;
                }
            })
            textBase.innerText = `Temos ${counterCidade} bases operacionais n${dadosEstados.pronome} ${dadosEstados.estado}`;
            textBaseEn.innerText = `Temos ${counterCidade} bases operacionais n${dadosEstados.pronome} ${dadosEstados.estado}`;

        }
        else if (counterCidade > 16) {
            const NomeAreaAtuacao = find('[js-area-atuacao]').getAttribute('js-area-atuacao');
            const DezenaBases = find('[js-dezenas-bases]');
            const DezenaBasesEn = find('[js-dezenas-bases-en]');
            const Dezena = parseInt(counterCidade / 10);

            textBase.innerHTML = `${NomeAreaAtuacao} presente em ${counterEstados}<br />estados brasileiros`;
            textBaseEn.innerHTML = `${NomeAreaAtuacao} present in ${counterEstados}<br />Brazilian states`;
            DezenaBases.innerHTML = `<span class="text-featured">+${Dezena}0 <span>Bases<br> Operacionais</span></span>`
            DezenaBasesEn.innerHTML = `<span class="text-featured">+${Dezena}0 <span>Bases<br> Operations</span></span>`
            find('[js-list-all-operation]').style.display = 'none';

        }
        else {
            textBase.innerText = `Temos ${counterCidade} bases operacionais em ${counterEstados} estados diferentes`;
            textBasesEn.innerText = `We have ${counterCidade} operational bases in ${counterEstados} different states`;
        }
    }

    Section_Base();

    function verifyHidroclean(){
        const url = new URL(window.location.href);
        const path = url.pathname;
        const slug = 'hidroclean';
        const isSlugPresent = path.includes(slug);
        const Base_Cidades = findAll('[js-cidade]');
        const counterCidade = Base_Cidades.length;

        if(isSlugPresent && counterCidade > 16){
            const textBase = find('[js-text-bases]');
            const NomeAreaAtuacao = find('[js-area-atuacao]').getAttribute('js-area-atuacao');
            textBase.innerHTML = `${NomeAreaAtuacao} presente em 21 <br />estados brasileiros`;
        }
    }

    verifyHidroclean()

}

export default PageSingleAreaAtuacao;